import React from 'react';
import Link from 'next/link';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';
import { withConfig } from '../utils/withConfig';

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await withConfig(locale)),
    },
  };
};

const NotFound = () => {
  const [t, { language }] = useTranslation();

  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push('/', '/', { locale: language });
    }, 3000);
  }, [language, router]);

  return (
    <div className="container error">
      <h3 className="main-title error__title">{t('page_not_found')}</h3>
      <Link href="/" locale={language}>
        <a className="error__link">{t('homepage_title')}</a>
      </Link>
      <style jsx>{`
        @import '../routesComponents/NotFoundPage/NotFoundPage.scss?';
      `}</style>
    </div>
  );
};

export default NotFound;
